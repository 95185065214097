<template>
  <div>
    <tz-banner :title="cms.page.title" :is-light="true" :has-overlay="false" />
    <div class="wl-legal">
      <div class="container">
        <section class="sh-wysiwyg">
          <tz-markdown :content="cms.page.content" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import TzBanner from '@trazi/tz-banner/src/tz-banner.vue';
// Helpers
import Meta from '@/assets/js/shared/misc/meta.mjs';

export default {
  components: {
    TzMarkdown,
    TzBanner
  },
  asyncData({ route }) {
    if (!process.server) {
      return;
    }

    const cms = {
      page: require(`@/assets/cms/pages/${route.name}.json`),
      global: require(`@/assets/cms/global.json`)
    };

    return {
      cms
    };
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
